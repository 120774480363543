import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { cssBreakpoints, gutterWidth } from 'theme/theme';

export const CmsFourArticlesHighlightBlockPageSectionBox = styled(Box)<{
  $isInPost?: boolean;
}>`
  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    ${(props) => `width: ${props.$isInPost ? '33%' : '25%'};`};
    &:nth-child(4) {
      ${(props) => props.$isInPost && `display: none;`};
    }
  }

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    width: 50%;
  }

  @media all and (max-width: ${cssBreakpoints.smDown}) {
    width: 100%;

    &:not(:first-child) {
      margin-top: ${(gutterWidth * 3) / 4}px;
    }
  }

  @media all and (min-width: ${cssBreakpoints.smDown}) and (max-width: ${cssBreakpoints.mdDown}) {
    &:not(:nth-child(1), :nth-child(2)) {
      margin-top: ${gutterWidth}px;
    }
  }

  a {
    position: relative;
    display: block;
    max-width: 240px;
  }
`;
