import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { RichTextHeadline } from 'components/Text/RichTextHeadline';
import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { CmsApiFourArticlesHighlightBlockData } from 'modules/cms/api/types/blocks/CmsApiFourArticlesHighlightBlock';
import { gutterWidth } from 'theme/theme';
import { identityFilter } from 'utils/functional/function';
import { isNotEmpty } from 'utils/functional/poly';

import { CmsFourArticlesHighlightBlockPageSection } from './CmsFourArticlesHighlightBlockPageSection';

type Props = {
  content: CmsApiPage;
  data: CmsApiFourArticlesHighlightBlockData;
  blockIndex: number;
  pageUpdated: string;
};

export function CmsFourArticlesHighlightBlock({
  content,
  data,
  blockIndex,
  pageUpdated,
}: Props) {
  const { subsite } = content;

  const ARTICLES = [
    data.pageOne,
    data.pageTwo,
    data.pageThree,
    data.pageFour,
  ].filter(identityFilter);

  const { headline, buttonText, buttonUrl } = data;

  const displayImages =
    ARTICLES.some((page) => page.image) ||
    isNotEmpty(subsite?.placeholderImages);

  return (
    <Box
      data-qa-id="cms-four-article-highlight"
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      width="100%"
    >
      {headline && (
        <Box mb={[gutterWidth / 2, null, null, gutterWidth]}>
          <RichTextHeadline>{headline}</RichTextHeadline>
        </Box>
      )}

      <Box
        display="flex"
        flexWrap="wrap"
        flex="0 0 auto"
        width="100%"
        mx={[-gutterWidth / 4, null, null, -gutterWidth / 2]}
      >
        {ARTICLES.map((page, index) => (
          <CmsFourArticlesHighlightBlockPageSection
            qaId={`four-article-highlight-section-${index}`}
            page={page}
            key={page.id}
            displayImages={displayImages}
            blockIndex={blockIndex}
            pageUpdated={pageUpdated}
            subsite={subsite}
            isInPost={content.type === 'POST'}
          />
        ))}
      </Box>

      {buttonText && buttonUrl && (
        <Box mt={['32px', null, '52px']} width="100%">
          <Button type="link" href={buttonUrl} variant="primary">
            {buttonText}
          </Button>
        </Box>
      )}
    </Box>
  );
}
