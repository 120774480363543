import { Box } from 'components/Box/Box';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { PlaceholderImage } from 'components/PlaceholderImage/PlaceholderImage';
import { H4 } from 'components/Text/H4';
import { HideContainer } from 'containers/HideContainer';
import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';
import { CmsApiFourArticlesHighlightBlockDataPage } from 'modules/cms/api/types/blocks/CmsApiFourArticlesHighlightBlock';
import { gutterWidth } from 'theme/theme';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { isNotEmpty } from 'utils/functional/poly';

import { CmsFourArticlesHighlightBlockPageSectionBox } from './CmsFourArticlesHighlightBlockPageSection.styled';

type Props = {
  page: CmsApiFourArticlesHighlightBlockDataPage;
  qaId: string;
  displayImages: boolean;
  blockIndex: number;
  pageUpdated: string;
  subsite: CmsApiSubsite | undefined;
  isInPost: boolean;
  className?: string;
};

export function CmsFourArticlesHighlightBlockPageSection({
  page,
  qaId,
  displayImages,
  blockIndex,
  pageUpdated,
  subsite,
  isInPost,
  className,
}: Props) {
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  const hasPlaceholder = isNotEmpty(subsite && subsite.placeholderImages);
  const hasImage = Boolean(page.image);
  const showPlaceholder =
    typeof window !== 'undefined' && !hasImage && hasPlaceholder;
  const showImage = hasImage || showPlaceholder;

  return (
    <CmsFourArticlesHighlightBlockPageSectionBox
      data-qa-id={qaId}
      // width={[1, 1, 1 / 2, 1 / 3]}
      px={[gutterWidth / 4, null, null, gutterWidth / 2]}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent={['center', 'flex-start']}
      className={className}
      flex="0 0 auto"
      $isInPost={isInPost}
    >
      {displayImages && (
        <Box
          position="relative"
          textAlign="center"
          mb={[gutterWidth / 4, null, gutterWidth / 2]}
        >
          {showImage ? (
            <StyledNativeLinkWithRef
              href={`/${page.url}`}
              tabIndex={-1}
              onClick={() => {
                trackClicked('CMS Block', {
                  block_position: blockIndex,
                  block_type: 'Four Articles Highlight',
                  cta_type: 'Image',
                  page_title: page.title,
                  page_updated: pageUpdated,
                });
              }}
              aria-label={page.title}
            >
              {showPlaceholder ? (
                <PlaceholderImage
                  postId={page.id}
                  placeholderImages={subsite?.placeholderImages || []}
                  alt={page.title.slice(0, 100)}
                  width={190}
                  height={190}
                  data-qa-id="four-article-highlight-section-image"
                  round
                />
              ) : (
                <FilestackImage
                  alt={page.image?.altText || page.title.slice(0, 100)}
                  data={page.image || null}
                  width={190}
                  height={190}
                  data-qa-id="four-article-highlight-section-image"
                  round
                  objectFit="cover"
                />
              )}
            </StyledNativeLinkWithRef>
          ) : (
            <HideContainer noWrapper xs sm>
              <div
                style={{
                  height: 190,
                }}
              />
            </HideContainer>
          )}
        </Box>
      )}
      <StyledNativeLinkWithRef
        href={`/${page.url}`}
        onClick={() => {
          trackClicked('CMS Block', {
            block_position: blockIndex,
            block_type: 'Four Articles Highlight',
            cta_type: 'Link',
            page_title: page.title,
            page_updated: pageUpdated,
          });
        }}
      >
        <H4 mb={0} lineHeight={1.25}>
          {page.title}
        </H4>
      </StyledNativeLinkWithRef>
    </CmsFourArticlesHighlightBlockPageSectionBox>
  );
}
